const obfuscateEmail = () => {
    const emails = document.querySelectorAll('a[href^="mailto:"]');

    function rot13Decode(str) {
        return str
            .split('')
            .map((char) => {
                const code = char.charCodeAt(0);
                if ((code >= 65 && code <= 77) || (code >= 97 && code <= 109)) {
                    return String.fromCharCode(code + 13);
                } else if (
                    (code >= 78 && code <= 90) ||
                    (code >= 110 && code <= 122)
                ) {
                    return String.fromCharCode(code - 13);
                } else {
                    return char;
                }
            })
            .join('');
    }

    emails.forEach((link) => {
        let mailtoValue = link.getAttribute('href').substring(7);
        let decodedMailtoValue = rot13Decode(mailtoValue);
        link.setAttribute('href', 'mailto:' + decodedMailtoValue);

        let nodes = link.childNodes;
        nodes.forEach((node) => {
            if (node.nodeType === 3) {
                node.textContent = rot13Decode(node.textContent);
            }
        });
    });
};

window.addEventListener('DOMContentLoaded', () => {
    obfuscateEmail();
});
