const waitForRecaptchaAndObserve = () => {
    // Get the form first
    const form = document.querySelector('form');
    if (!form) return;

    // Create a MutationObserver to watch for the recaptcha element being added
    const bodyObserver = new MutationObserver((mutations) => {
        // Check each mutation to see if our target element was added
        for (const mutation of mutations) {
            if (
                mutation.type === 'childList' &&
                mutation.addedNodes.length > 0
            ) {
                // Look for the recaptcha root element
                const recaptchaRootEL =
                    document.querySelector('.cmppreviewroot');
                if (recaptchaRootEL) {
                    console.log(
                        'Recaptcha root element found:',
                        recaptchaRootEL
                    );

                    // Access the shadow root (if available)
                    if (recaptchaRootEL.shadowRoot) {
                        console.log(
                            'Shadow root found:',
                            recaptchaRootEL.shadowRoot
                        );

                        // Try to find the iframe inside the shadow DOM
                        const recaptchaIframe =
                            recaptchaRootEL.shadowRoot.querySelector(
                                '.cmplazypreviewiframe'
                            );
                        if (recaptchaIframe) {
                            console.log(
                                'Recaptcha iframe found in shadow DOM:',
                                recaptchaIframe
                            );
                            observeRecaptchaIframe(recaptchaIframe);
                            bodyObserver.disconnect();
                        } else {
                            // If iframe not found immediately, observe the shadow root
                            observeShadowRoot(recaptchaRootEL.shadowRoot);
                            bodyObserver.disconnect();
                        }
                    } else {
                        // If shadowRoot is not accessible (closed shadow DOM), we need an alternative approach
                        console.log(
                            'Shadow root not accessible - using alternative approach'
                        );

                        // Try using the experimental Shadow DOM v1 method (works in some browsers)
                        try {
                            const shadowRoot =
                                recaptchaRootEL.openOrClosedShadowRoot;
                            if (shadowRoot) {
                                const iframe = shadowRoot.querySelector(
                                    '.cmplazypreviewiframe'
                                );
                                if (iframe) {
                                    observeRecaptchaIframe(iframe);
                                    bodyObserver.disconnect();
                                }
                            }
                        } catch (e) {
                            console.log('Cannot access closed shadow root:', e);

                            // If shadow DOM is closed and we can't access it directly,
                            // we need to try a different approach
                            useAlternativeMethod(recaptchaRootEL);
                        }
                    }
                }
            }
        }
    });

    // Start observing the document body
    bodyObserver.observe(document.body, {
        childList: true,
        subtree: true,
    });
};

// Function to observe a shadow root for changes
const observeShadowRoot = (shadowRoot) => {
    const shadowObserver = new MutationObserver((mutations) => {
        const iframe = shadowRoot.querySelector('.cmplazypreviewiframe');
        if (iframe) {
            observeRecaptchaIframe(iframe);
            shadowObserver.disconnect();
        }
    });

    shadowObserver.observe(shadowRoot, {
        childList: true,
        subtree: true,
    });
};

// Function when we can't access shadow DOM directly
const useAlternativeMethod = (recaptchaRootEL) => {
    recaptchaRootEL.addEventListener('load', (event) => {
        console.log('Load event from shadow DOM:', event);
        // Try to identify when the iframe is loaded through events
    });

    const checkInterval = setInterval(() => {
        // Check if the recaptcha is activated (e.g., by checking parent element styles)
        const isVisible = recaptchaRootEL.style.display !== 'none';
        if (isVisible) {
            console.log('Recaptcha appears to be visible now');

            const form = document.querySelector('form');
            if (form) {
                form.classList.remove('recaptcha-visible');
                const submitButton = form.querySelector(
                    'button[type="submit"]'
                );
                if (submitButton) {
                    submitButton.disabled = false;
                }
            }

            clearInterval(checkInterval);
        } else {
            const form = document.querySelector('form');
            form.classList.add('recaptcha-visible');
        }
    }, 500);
};

// Function to observe the recaptcha iframe for style changes
const observeRecaptchaIframe = (recaptchaIframe) => {
    // Check initial state immediately when we find the iframe
    const initialDisplayStyle =
        window.getComputedStyle(recaptchaIframe).display;
    console.log('Initial recaptcha iframe display style:', initialDisplayStyle);

    // Only disable the submit button if the recaptcha is initially visible
    if (initialDisplayStyle !== 'none') {
        console.log(
            'Recaptcha iframe is initially visible - disabling submit button'
        );
        const form = document.querySelector('form');
        if (form) {
            form.classList.add('recaptcha-visible');
            const submitButton = form.querySelector('button[type="submit"]');
            if (submitButton) {
                submitButton.disabled = true;
            }
        }
    } else {
        const form = document.querySelector('form');
        form.classList.remove('recaptcha-visible');
        console.log(
            'Recaptcha iframe is initially hidden - submit button remains enabled'
        );
    }

    // Define a callback function for style changes
    const styleCallback = (mutationsList, observer) => {
        for (const mutation of mutationsList) {
            if (
                mutation.type === 'attributes' &&
                mutation.attributeName === 'style'
            ) {
                // Check if display property has changed
                const displayStyle =
                    window.getComputedStyle(recaptchaIframe).display;
                console.log('Display style changed:', displayStyle);

                if (displayStyle !== 'none') {
                    console.log(
                        'Recaptcha iframe is now visible - disabling submit button'
                    );
                    // Disable form submission when recaptcha becomes visible
                    const form = document.querySelector('form');
                    if (form) {
                        form.classList.remove('recaptcha-visible');

                        const submitButton = form.querySelector(
                            'button[type="submit"]'
                        );
                        if (submitButton) {
                            submitButton.disabled = true;
                        }
                    }
                } else {
                    console.log(
                        'Recaptcha iframe is now hidden - enabling submit button'
                    );
                    // Enable form submission when recaptcha becomes hidden
                    const form = document.querySelector('form');
                    if (form) {
                        form.classList.add('recaptcha-visible');

                        const submitButton = form.querySelector(
                            'button[type="submit"]'
                        );
                        if (submitButton) {
                            submitButton.disabled = false;
                        }
                    }
                }
            }
        }
    };

    // Create an observer for style changes
    const styleObserver = new MutationObserver(styleCallback);

    // Start observing the iframe for style attribute changes
    styleObserver.observe(recaptchaIframe, {
        attributes: true,
        attributeFilter: ['style'],
    });
};

// Initialize
window.addEventListener('DOMContentLoaded', () => {
    waitForRecaptchaAndObserve();
});

// Fallback if loaded after DOMContentLoaded
if (document.readyState !== 'loading') {
    waitForRecaptchaAndObserve();
}

// Also try again after a short delay
window.addEventListener('load', () => {
    setTimeout(waitForRecaptchaAndObserve, 1000);
});
