const theme = [
    {
        featureType: 'all',
        elementType: 'labels.text',
        stylers: [
            {
                color: '#878787',
            },
        ],
    },
    {
        featureType: 'all',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'all',
        stylers: [
            {
                color: '#f9f5ed',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                color: '#f5f5f5',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#c9c9c9',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#aee0f4',
            },
        ],
    },
];

let map;
let markers = [];
let infoWindows = [];

window.ksInitGoogleMaps = async (mapElement) => {
    if (google?.maps === undefined) {
        return;
    }

    const { Map } = await google.maps.importLibrary('maps');
    const locations = JSON.parse(
        decodeURIComponent(mapElement.dataset.locations)
    );
    const descriptions = JSON.parse(
        decodeURIComponent(mapElement.dataset.descriptions)
    );

    const geocoder = new google.maps.Geocoder();
    map = new google.maps.Map(mapElement, {
        zoom: 17,
        disableDefaultUI: false,
        styles: theme,
        streetViewControl: false,
    });

    map.addListener('zoom_changed', () => {
        infoWindows.forEach((infoWindow) => infoWindow.close());
    });

    locations.forEach(function (location, index) {
        geocoder.geocode({ address: location }, function (results, status) {
            if (status === 'OK') {
                markers[index] = new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                });

                // Todo: Triggers forEach marker...
                enableGoogleMapsInfoWindows(
                    index,
                    location,
                    descriptions[index],
                    map,
                    markers
                );
                adjustGoogleMapsCenter(map, markers);
            } else {
                alert(
                    'Geocode was not successful for the following reason: ' +
                        status
                );
            }
        });
    });
};

/**
 * Adjust the center of the map based on the centroid of all locations
 **/
function adjustGoogleMapsCenter(map, markers) {
    const centroid = calculateCentroid(markers);
    let offsetLatLng;

    if (window.innerWidth <= 768) {
        const latOffset = 0; // had been 0.05 for another zoom level and a single marker for better box display on smartphone
        offsetLatLng = new google.maps.LatLng(
            centroid.lat,
            centroid.lng + latOffset
        );
    } else {
        offsetLatLng = centroid;
    }

    map.setCenter(offsetLatLng);
}

function calculateCentroid(markers) {
    let totalLat = 0;
    let totalLng = 0;

    markers.forEach((marker) => {
        const latLng = marker.getPosition();
        if (latLng) {
            totalLat += latLng.lat();
            totalLng += latLng.lng();
        }
    });

    const centroidLat = totalLat / markers.length;
    const centroidLng = totalLng / markers.length;

    return { lat: centroidLat, lng: centroidLng };
}

function enableGoogleMapsInfoWindows(
    index,
    location,
    description,
    map,
    markers
) {
    // Create an info window for each marker

    infoWindows[index] = new google.maps.InfoWindow({
        content: `<div class="location-map-info-box">${description}</div>`,
        disableAutoPan: true,
        pixelOffset: new google.maps.Size(30, 0), // Adjust the offset as needed
        alignBottom: true,
        position: markers[index].position,
        closeBoxURL: '',
        isHidden: false,
        pane: 'floatPane',
        enableEventPropagation: true,
    });

    //infoWindows[index].open(map, markers[index]);

    // Add click event listener to marker
    markers[index].addListener('click', function () {
        // Close all other info windows
        infoWindows.forEach((infoWindow) => infoWindow.close());
        // Open the info window for the clicked marker
        infoWindows[index].open(map, markers[index]);
        // Center the map on the clicked marker with a smooth animation
        map.panTo(this.getPosition());
    });
}
