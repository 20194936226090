const video = () => {
    const videos = document.querySelectorAll('video');

    const preloadVideo = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.setAttribute('preload', 'auto');
                entry.target.setAttribute('autoplay', 'autoplay');
                entry.target.play();
                observer.unobserve(entry.target);
            }
        });
    };

    const observer = new IntersectionObserver(preloadVideo, {
        root: null,
        rootMargin: '200px',
        threshold: 0
    });

    videos.forEach(video => {
        observer.observe(video);
    });
};

document.addEventListener('DOMContentLoaded', video);
