import Swiper from 'swiper';
import { Navigation, EffectFade } from 'swiper/modules';

const initSwiper = () => {
    const swiper = document.querySelectorAll('.swiper');

    if (swiper.length === 0) {
        return;
    }

    swiper.forEach((swiper) => {
        if (
            swiper.classList.contains('swiper-content') |
            swiper.classList.contains('swiper-image')
        ) {
            return;
        }

        const data = swiper.dataset.swiper;
        const options = JSON.parse(data);

        new Swiper(swiper, {
            ...options,
            modules: [Navigation, EffectFade],
        });
    });
};

window.addEventListener('DOMContentLoaded', () => {
    initSwiper();
});
