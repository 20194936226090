// ### CSS
import 'swiper/css';
import './vendor/fontawesome/css/sharp-solid.min.css';
import './vendor/fontawesome/css/all.min.css';
import './styles/index.scss';

// ### JavaScript

// Vendor Modules
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/tab';

// Custom JS
import './js/email_obfuscator';
import './js/swiper';
import './js/navbar';
import './js/dropdown';
import './js/video';
import './js/google_maps';
import './js/cookie_consent';
