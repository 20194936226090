import { debounce } from './utils';

const navbar = () => {
    const header = document.querySelector('.header');

    const toggleClass = () => {
        if (window.scrollY > 0) {
            header.classList.add('scrolled');
        } else {
            header.classList.remove('scrolled');
        }
    };

    window.addEventListener('scroll', debounce(toggleClass, 100));
    window.addEventListener('load', toggleClass);
};

window.addEventListener('DOMContentLoaded', () => {
    navbar();
});
